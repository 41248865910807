import Box from "@mui/material/Box";
import Blockquote from "components/Blockquote";
import { Markdown } from "components/Markdown";

import * as React from "react";
import BlogLayout from "src/components/BlogLayout";
import Paragraph from "src/components/Paragraph";
import ellenImg from "src/images/about-us/Ellen Hurtado.jpg";
import emmanuelImg from "src/images/about-us/Emmanuel Carrie.jpg";

export default function Page() {
  return (
    <BlogLayout slug="eocp-new-leaders-making-their-mark">
      <Paragraph component="div">
        <Markdown>
          {`EOCP's staff are the anchor of our tireless work in supporting people to break out of cycles of homelessness and transform their lives.

Over the past couple years, some incredible new leaders have joined our team --- bringing not only decades of critical experience, but also new visions for success, and a renewed and energized spirit for the future of our organization. These newest team members are already making their mark in pushing EOCP's work forward!`}
        </Markdown>
      </Paragraph>
      <Blockquote component="div">
        Meet Ellen
        <Paragraph>Ellen Hurtado, Director of Programs at Crossroads</Paragraph>
      </Blockquote>

      <Paragraph component="div">
        <Box
          component="img"
          src={ellenImg}
          sx={{ width: "40%", float: "left" }}
          mr={3}
          mb={3}
        />
        <Markdown>
          {`My respect and admiration for EOCP has been growing since 10 years ago, when I was working with Abode Services --- which, like EOCP, was part of the Oakland Path Rehousing Initiative (OPRI). At our OPRI meetings, I noticed how when EOCP's power duo of Wendy Jackson and Kim Parrish would speak up, everyone listened. I got to know Wendy a little bit over time, and learning about what she and Kim grew at EOCP made me want to come here. Since joining the team earlier this year, I have learned even more about their dynamic vision for what could be at EOCP --- and just how dedicated all the staff are in serving people in their community. **There is an amazing history and lineage of what has happened here, and it's now about strengthening the good work into the next generation.**

A main part of my job is to **build up our leaders and boost them to step up and take action,** so they in turn can support their own teams. Sometimes, you just need someone to have your back, help you resource your work, and to say, "Yes, do it!" when you have a powerful idea or motivation to make change happen. Everyone here already comes to this work with their heart. I often think that **my role is about coaching managers** so that one day, they can easily move up as leaders --- it's about training them to take my job! My focus is ensuring that staff have the knowledge and information they need about everything from trauma-informed care, to approaching domestic violence issues, to effectively managing their budgets.

My hope is that in addition to our town halls with shelter residents, we also hold regular all-staff enrichment and training meetings for the 50+ people who work at Crossroads. It comes with the same intention: I want to make sure **our workplace is one where staff can share their voice, feel cared for, and receive the kudos they deserve.** The COVID pandemic created a time of isolation and emergency response for our staff. Now that we can all gather together in the building more safely, there are more possibilities for how we can strengthen our team.`}
        </Markdown>
      </Paragraph>
      <Blockquote component="div">
        Meet Emmanuel
        <Paragraph>Emmanuel Carrie, Family Matters Shelter Manager</Paragraph>
      </Blockquote>

      <Paragraph component="div">
        <Box
          component="img"
          src={emmanuelImg}
          sx={{ width: "40%", float: "right" }}
          ml={3}
          mb={3}
        />
        <Markdown>
          {`I joined EOCP in mid-March of this year, but my commitment to social services work began over 20 years ago. I have been mentored by incredible leaders who took chances on me and nurtured my professional growth. At Family Matters,** I am excited to pay it forward by supporting our team to grow in their own careers,** so that each individual can in turn show up with the compassion, confidence, and motivation that we are channeling for our residents, as well.

My mentorship style is informed by my studies and my own practice as a poet and a performer. I believe storytelling is so important: When we know who we are within and to ourselves, it allows us to show up for our residents with greater integrity. I start by getting to know each colleague as a person, and hearing their stories about what's important to them in their life: Work? Community? Church? Hobbies?

We all show up to our jobs as full humans, and I want to support each individual's sense of themselves, while also encouraging them to build a strong sense of professional capability and confidence that they can tap into. Sometimes simple tools make the most difference: balancing our work time with time to "Relax, Relate, and Release," saying affirmations, and putting our goals down in writing.

Our day-to-day work is about helping shelter residents to set objectives for their own life paths, to break free of being stagnant, and to **guide each family towards being their own biggest champion as they move into permanent housing.** I am here to keep making Family Matters an inspirational community where everyone wants to see themselves grow and transform.`}
        </Markdown>
      </Paragraph>
    </BlogLayout>
  );
}
